import './OptionsList.css';
import './OptionsListItem';
import OptionsListItem from "./OptionsListItem";

const OptionsList = ({id, items, currentValue, onChange, allowEditing}) => (
    <>
        <ul>
            {items.map(item => (
                <OptionsListItem
                    key={id + '_' + item.value}
                    id={id + '_' + item.value}
                    name={id}
                    label={item.label}
                    value={item.value}
                    isChecked={item.value === currentValue}
                    onChange={onChange}
                    allowEditing={allowEditing}
                />
            ))}
        </ul>
    </>
)

export default OptionsList