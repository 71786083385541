import {useMemo, useState} from "react";
import OptionsList from "../options/OptionsList";
import LoadingSpinner from "../loading/LoadingSpinner";
import Menu from "./Menu";

const Rsvp = ({
    group,
    attending,
    setAttending,
    pitchType,
    setPitchType,
    foodOptions,
    setFoodOptions,
    songRequest,
    setSongRequest,
    songRequestPlaceholder,
    completed,
    submitting,
    error,
    allowEditing,
    mealKey,
    eveningKey,
    onSubmit
}) => {
    const foodMenu = [
        {
            name: mealKey,
            options: [
                {
                    value: 'chicken_katsu',
                    label: 'Chicken Katsu Curry',
                    isKidsOnly: false
                },
                {
                    value: 'cauliflower_katsu',
                    label: 'Cauliflower Katsu Curry (Vegan, Gluten Free)',
                    isKidsOnly: false
                },
                {
                    value: 'kidsu_chicken',
                    label: 'Kidsu Curry - Panko Chicken',
                    isKidsOnly: true
                },
                {
                    value: 'kidsu_cauliflower',
                    label: 'Kidsu Curry - Cauliflower',
                    isKidsOnly: true
                },
                {
                    value: 'none',
                    label: 'None',
                    isKidsOnly: false
                }
            ]
        },
        {
            name: eveningKey,
            options: [
                {
                    value: 'traditional_pasty',
                    label: 'Traditional Cornish Pasty',
                    isKidsOnly: false
                },
                {
                    value: 'vegan_pasty',
                    label: 'Vegan Pasty',
                    isKidsOnly: false
                },
                {
                    value: 'kids_sausage_roll',
                    label: 'Sausage Roll - Kids Menu',
                    isKidsOnly: true
                },
                {
                    value: 'none',
                    label: 'None',
                    isKidsOnly: false
                }
            ]
        }
    ]

    const setPersonDietaryRequirements = (personId, dietaryRequirements) => {
        setFoodOptions((prevState) => {
            let newState = prevState.map(e => e);
            let personChoices = newState.find(e => e.id === personId);
            if (!personChoices) return;
            personChoices.dietaryRequirements = dietaryRequirements;
            return newState;
        })
    }

    const setPersonFoodChoice = (personId, mealName, selection) => {
        setFoodOptions((prevState) => {
            let newState = prevState.map(e => e);
            let personChoices = newState.find(e => e.id === personId);
            if (!personChoices) return;
            let meal = personChoices.meals.find(e => e.name === mealName);
            meal.value = selection;
            return newState;
        })
    }

    const selectedFoodChoice = (personId, meal) => {
        return foodOptions
            ?.find(e => e.id === personId)
            ?.meals
            ?.find(e => e.name === meal)
            ?.value ?? null
    }

    const optionsForMenu = (meal, isChild) => {
        return foodMenu
            .find(e => e.name === meal)
            ?.options
            ?.filter(m => !m.isKidsOnly || isChild) ?? null
    }

    const pronoun = () => !!group && group.length > 1 ? "We" : "I"

    const isFormValid = () => {
        if (!allowEditing || attending == null) {
            return false
        }
        if (attending === 'false') {
            return true
        }
        if (pitchType == null || !foodOptions) {
            return false;
        }
        for (const member of foodOptions) {
            if (member.meals.some(x => !x.value)) {
                return false;
            }
        }
        return true
    }

    const submitEnabled = useMemo(
        () => isFormValid(),
        [attending, pitchType, foodOptions]
    )

    return <div className={"rsvp"}>
        <div className={"form-container"}>
            <h1>Rsvp</h1>
        </div>
        <div className={"form-container"}>
            <hr/>
            <OptionsList
                id={'attending'}
                items={[
                    {
                        value: 'true',
                        label: `${pronoun()} will be attending.`
                    },
                    {
                        value: 'false',
                        label: `${pronoun()} will not be attending.`
                    }
                ]}
                currentValue={attending}
                onChange={setAttending}
                allowEditing={allowEditing}
            />
        </div>
        {attending === 'true' && (
            <div className={"form-container"}>
                <hr/>
                <OptionsList
                    id={'pitch_type'}
                    items={[
                        {
                            value: 'standard',
                            label: `${pronoun()} require a standard camping pitch.`
                        },
                        {
                            value: 'electric',
                            label: `${pronoun()} require an electric camping pitch.`
                        },
                        {
                            value: 'none',
                            label: `${pronoun()} do not require a camping pitch.`
                        }
                    ]}
                    currentValue={pitchType}
                    onChange={setPitchType}
                    allowEditing={allowEditing}
                />

                {pitchType === 'electric' && (
                    <p>
                        We will try our best to allocate you an electric pitch. However, due to
                        limited electric pitches we <strong>can not guarantee</strong> an electric pitch.
                    </p>
                )}
            </div>
        )}
        {attending === 'true' && !!pitchType && (
            <div>
                <div className={'section-break'} />
                <div className={'form-container'}>
                    <h1>Menu</h1>
                </div>
                <Menu
                    showKidsMenu={foodOptions.some(x => x.isChild)}
                    mealKey={mealKey}
                    eveningKey={eveningKey}/>
                { foodOptions?.map(person => (
                    <>
                        <div className={'section-break'}/>
                        <div className={'form-container'}>
                            {foodOptions.length > 1 && <h1>{person.name} - Food Choices</h1>}
                            {foodOptions.length === 1 && <h1>Food Choices</h1>}
                            <hr/>
                            <h3 style={{margin: '2rem 0 0'}}>
                                {allowEditing ? 'Please enter any dietary requirements or allergies below.' : 'Dietary requirements and allergies'}
                            </h3>
                            <input
                                style={{marginBottom: '2rem'}}
                                type={"text"}
                                placeholder={"Enter here..."}
                                disabled={!allowEditing}
                                value={!!person.dietaryRequirements || allowEditing ? person.dietaryRequirements : 'None'}
                                onChange={(e) => setPersonDietaryRequirements(person.id, e.target.value)}/>
                            {person.meals.map(meal => (
                                <>
                                    <h3 key={`${person.id}_${meal.name}_title`}>{meal.name}</h3>
                                    <OptionsList
                                        key={`${person.id}_${meal.name}`}
                                        id={`${person.id}_${meal.name}`}
                                        items={optionsForMenu(meal.name, person.isChild)}
                                        currentValue={selectedFoodChoice(person.id, meal.name)}
                                        onChange={(s) => setPersonFoodChoice(person.id, meal.name, s)}
                                        allowEditing={allowEditing}
                                    />
                                </>
                            ))}
                        </div>
                    </>
                )) }
            </div>
        )}
        {attending === 'true' && !!pitchType && (
            <div>
                <div className={'section-break'}/>
                <div className={'form-container'}>
                    <h1>Song Request</h1>
                </div>
                <div className={'form-container'}>
                    <hr/>
                    <input
                        type={"text"}
                        placeholder={allowEditing && !!songRequestPlaceholder ? songRequestPlaceholder : (allowEditing ? "Enter here..." : "")}
                        disabled={!allowEditing}
                        value={songRequest ?? ""}
                        onChange={(e) => setSongRequest(e.target.value)}/>
                </div>
            </div>
        )}
        {(attending === 'false' || attending === 'true' && !!pitchType) && (
            <div className={'footer'}>
                {allowEditing && !submitting && !completed && !!error && (
                    <p>Something went wrong. Please try again.</p>
                )}
                {allowEditing && !submitting && !completed && (
                    <button
                        id={"submit"}
                        onClick={() => {
                            if (isFormValid()) {
                                onSubmit()
                            }
                        }}
                        disabled={!submitEnabled}>
                        Submit
                    </button>
                )}
                {submitting && (<LoadingSpinner/>)}
                {completed && (<p>Thank you for your reply.</p>)}
            </div>
        )}
    </div>
};

export default Rsvp