import Timeline from "./Timeline";

const Details = ({names}) => (
    <>
        <div className={"form-container invitation"}>
            <p className={"formal"}>
                Dear {names}
            </p>
            <p>
                We would love for you to join us, here are the further details for our celebration in Croyde.
            </p>
            <p>
                We are doing things slightly differently, we will already be married. So with no ceremony on the day we have a morning of beach activities planned (weather dependent). Activities optional but we would love you all to join us in the fun, Spectators welcome.
            </p>
            <p>
                There will be some down time after the beach activities to let everyone get ready for the celebrations ahead.
            </p>
            <p>
                The celebrations will continue from the afternoon in the marquee with live music and a disco, accompanied by food and drinks.
            </p>
            <h4>Accommodation</h4>
            <p>
                We have complementary camping available at Ocean Pitch campsite. Just bring your tent and camping kit. Electric and non electric pitches available. Check in anytime from midday Friday. Check out 10:30am Sunday.
                No Dogs, caravans or motorhomes allowed, sorry!
            </p>
            <p>
                If camping is not for you, Croyde has plenty of alternatives to book your own stay.
            </p>
            <h4>Beach Activities</h4>
            <p>
                On Saturday morning, we would love for you all to join us on the beach at Croyde Bay. You can hire surf equipment from Ocean Pitch if needed, and then head over to catch some waves.
            </p>
            <p>
                Afterwards, we will be setting up a rounders match on the beach. Team Spratt vs Team Clements. We encourage you all to wear your favourite sports team shirt for the match.
            </p>
            <h4>Dress Code</h4>
            <p>
                Semi formal attire (e.g. suit and tie / cocktail dress) for the marquee celebrations, which begin Saturday 1pm onwards.
            </p>
            <h4>Gifts</h4>
            <p>
                Your presence is all we wish for. As everyone is travelling from afar to celebrate in Devon with us, we feel very lucky to have you all join us to celebrate.
            </p>
            <h4>Contact</h4>
            <p>
                If any questions or queries please do give us a call or text
                07706 135241 (Rich) / 07786 538109 (Lizzy)
            </p>
        </div>
        <Timeline />
        <img className={"full-width"} src={"map.svg"}/>
    </>
)

export default Details;