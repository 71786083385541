const Menu = ({showKidsMenu, mealKey, eveningKey}) => (
    <div className={'form-container'}>
        <div className={'menu-section'}>
            <hr/>
            <h2>{mealKey}</h2>
            <div className={'meal'}>
                <h3>Panko Chicken Katsu</h3>
                <p>
                    English crispy panko chicken, sweet & mild homemade katsu curry sauce,
                    sticky rice, organic North Devon rainbow slaw, salted edamame beans,
                    Biff’s pinkled onions, coriander, and sesame seeds.
                </p>
                <h3>Cauliflower Katsu (Vg, GF)</h3>
                <p>
                    Crispy panko cauliflower, sweet & mild homemade katsu curry sauce,
                    sticky rice, organic North Devon rainbow slaw,
                    salted edamame beans, Biff’s pinkled onions, coriander, mixed sesame seeds.
                </p>
                {showKidsMenu && (
                    <>
                        <div className={'meal'}>
                            <h3>Kidsu Curry - Panko Chicken</h3>
                            <p>
                                Childs portion (half size of an adults) English crispy panko chicken,
                                sweet & non-spicy homemade katsu curry sauce, sticky rice and organic
                                North Devon rainbow slaw.
                            </p>
                            <h3>Kidsu Curry - Cauliflower (Vg, GF)</h3>
                            <p>
                                Childs portion (half size of an adults) English crispy cauliflower,
                                sweet & non-spicy homemade katsu curry sauce, sticky rice and organic
                                North Devon rainbow slaw.
                            </p>
                        </div>
                    </>
                )}
            </div>
        </div>
        <div className={'menu-section'}>
            <hr/>
            <h2>{eveningKey}</h2>
            <div className={'meal'}>
                <h3>Traditional Cornish Pasty</h3>
                <h3>Vegan Pasty (Vg)</h3>
                {showKidsMenu && (
                    <h3>Sausage Roll - Kids Menu</h3>
                )}
            </div>
        </div>
    </div>
)

export default Menu;