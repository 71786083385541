import './OptionsList.css';
const OptionsListItem = ({id, value, name, label, isChecked, onChange, allowEditing}) => (
    <li>
        <input
            id={id}
            type={'radio'}
            value={value}
            name={name}
            onChange={(e) => onChange(e.target.value)}
            checked={isChecked}
            disabled={!allowEditing}/>
        <label htmlFor={id}>
            {label}
        </label>
        <div className="check"></div>
    </li>
)

export default OptionsListItem