import './App.css';
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import axios from "axios";
import LoadingSpinner from "./loading/LoadingSpinner";
import Rsvp from "./rsvp/Rsvp";
import Details from "./rsvp/Details";

function App() {
    const host = "b13ui8yzmd.execute-api.eu-west-2.amazonaws.com"
    const stage = "croyde24"

    const mealKey = "Daytime"
    const eveningKey = "Evening"

    const posterRef = useRef(null);
    const [showFixedNavigation, setShowFixedNavigation] = useState(false);
    const [enteredCode, setEnteredCode] = useState("");
    const [code, _] = useState(window.location.pathname.replace("/", ""));
    const [foundInvite, setFoundInvite] = useState(false);
    const [loadingGroup, setLoadingGroup] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [error, setError] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [group, setGroup] = useState(null);
    const [allowEditing, setAllowEditing] = useState(true);
    const [attending, setAttending] = useState(null);
    const [pitchType, setPitchType] = useState(null);
    const [foodOptions, setFoodOptions] = useState(null);
    const [songRequestPlaceholder, setSongRequestPlaceholder] = useState("Enter here");
    const [songRequest, setSongRequest] = useState(null);
    const [currentItem, setCurrentItem] = useState('details');

    const allNames = () => {
        if (!group) {
            return []
        }
        if (group.length > 1) {
            return group.slice(0, -1).join(", ") + ' & ' + group.slice(-1)
        } else {
            return group[0]
        }
    }

    const makeRequest = (path, body) => {
        return axios.post(
            `https://${host}/${stage}/${path}`,
            body
        )
    }

    const submit = () => {
        setError(null);
        setSubmitting(true);

        const body = attending === 'true' ? {
                "ID": code,
                "Attending": attending,
                "PitchRequired": pitchType,
                "SongRequest": songRequest,
                "Members": foodOptions.map(m => (
                    {
                        "ID": m.id,
                        "DietaryRequirements": m.dietaryRequirements ?? "",
                        "MealChoice": m.meals.find(x => x.name === mealKey).value,
                        "EveningChoice": m.meals.find(x => x.name === eveningKey).value
                    }
                ))
        } : {
            "ID": code,
            "Attending": attending
        }

        makeRequest(
            "rsvp",
            body
        )
            .then(_ => {
                setCompleted(true);
                setTimeout(() => {
                    fetchInvite(true)
                }, 5000)
            })
            .catch(error => {
                setError(error)
            })
            .finally(() => {
                setSubmitting(false);
            })
    }

    useLayoutEffect(() => {
        const handleScroll = () => {
            if (!!posterRef?.current) {
                const scrollBottom = posterRef.current.offsetTop + posterRef.current.height - window.scrollY
                setShowFixedNavigation(scrollBottom < 0)
            }
        }

        window.addEventListener('scroll', handleScroll)
        window.addEventListener('resize', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
            window.removeEventListener('resize', handleScroll)
        }
    }, []);

    const navigation = (fixed) => (
        <nav
            className={fixed ? "navigation fixed" : "navigation"}>
            <ul>
                <li onClick={() => setCurrentItem('details')}>
                    <p className={currentItem === 'details' ? 'selected' : ''}>Details</p>
                </li>
                <li onClick={() => setCurrentItem('invitation')}>
                    <p className={currentItem === 'invitation' ? 'selected' : ''}>Invitation</p>
                </li>
                <li onClick={() => setCurrentItem('rsvp')}>
                    <p className={currentItem === 'rsvp' ? 'selected' : '' }>RSVP</p>
                </li>
            </ul>
        </nav>
    )

    const fetchInvite = (removeCompleted = false) => {
        if (!code) {
            return
        }
        setLoadingGroup(true)
        setError(null)
        makeRequest("groups", {"Code": code.toUpperCase()})
            .then((response) => {
                const data = response.data
                if (!!data && !!data.Members) {
                    setAttending(data.Attending)
                    setPitchType(data.PitchRequired)
                    setAllowEditing(!data.Submitted)
                    setGroup(data.Members.map(p => p.InformalName))
                    setFoodOptions(
                        data.Members.map(p => ({
                            id: p.ID,
                            name: p.InformalName,
                            isChild: p.IsChild,
                            meals: [
                                {
                                    name: mealKey,
                                    value: p.MealChoice
                                },
                                {
                                    name: eveningKey,
                                    value: p.EveningChoice
                                }
                            ],
                            dietaryRequirements: p.DietaryRequirements
                        }))
                    )
                    setSongRequest(data.SongRequest)
                    setSongRequestPlaceholder(data.SongPlaceholder)
                    if (removeCompleted) {
                        setCompleted(false)
                    }
                    setFoundInvite(true)
                } else {
                    setFoundInvite(false)
                }
            })
            .catch(() => {
                setError("Unable to find invitation")
            })
            .finally(() => {
                setLoadingGroup(false)
            })

    }

    useEffect(
        () => fetchInvite(),
        [code]
    )

    const showLoadingGroup = () => (
        <LoadingSpinner className={'spinner'}/>
    )

    const showEnterCode = () => (
        <>
            <div className={'section-break'}/>
            <div className={'form-container'}>
                <p>Please enter your code to access your invitation</p>
                {!!error && (
                    <p className={"error"}>{error}</p>
                )}
                <input
                    id={"codeInput"}
                    type={"text"}
                    placeholder={"Enter here..."}
                    value={enteredCode ?? code ?? ""}
                    onChange={e => setEnteredCode(e.target.value.replace(/o/gi, "0").toUpperCase())}/>
                <div className={'footer'}>
                    <button
                        id={"submit"}
                        onClick={() => {
                            window.location = `/${enteredCode}`
                        }}>
                        Submit
                    </button>
                </div>
            </div>
        </>
    )

    const showDetails = () => (
        <>
            <Details names={allNames()}/>
        </>
    )

    const showInvitation = () => (
        <>
            <img className={"full-width"} src={"invitation.png"}/>
            <img className={"full-width"} src={"invitation-back.png"}/>
        </>
    )

    const showRsvp = () => (
        <Rsvp
            key={'rsvp'}
            group={group}
            completed={completed}
            error={error}
            submitting={submitting}
            attending={attending}
            setAttending={setAttending}
            pitchType={pitchType}
            setPitchType={setPitchType}
            songRequest={songRequest}
            setSongRequest={setSongRequest}
            foodOptions={foodOptions}
            setFoodOptions={setFoodOptions}
            songRequestPlaceholder={songRequestPlaceholder}
            allowEditing={allowEditing && !completed}
            mealKey={mealKey}
            eveningKey={eveningKey}
            onSubmit={submit}/>
    )

    return (
        <>
            {loadingGroup && showLoadingGroup()}
            {!loadingGroup && !foundInvite && showEnterCode()}
            {!loadingGroup && foundInvite && (
                <>
                    <img style={{marginBottom: '-5px'}} ref={posterRef} className={"full-width"} src={"poster.svg"}/>
                    {navigation(false)}
                    {showFixedNavigation && navigation(true)}
                </>
            )}
            {!loadingGroup && foundInvite && currentItem === 'details' && showDetails()}
            {!loadingGroup && foundInvite && currentItem === 'invitation' && showInvitation()}
            {!loadingGroup && foundInvite && currentItem === 'rsvp' && showRsvp()}
        </>
    );
}

export default App;
