const Timeline = () => (
    <div className={'timeline-container'}>
        <div className={'form-container'}>
            <h1>Timeline</h1>
            <hr/>
            <h2>Friday</h2>
            <div className={"section"}>
                <img src={"camping-icon.svg"}/>
                <p>Campers check-in from 12pm</p>
            </div>
            <hr/>
            <h2>Saturday Morning</h2>
            <div className={"section"}>
                <img src={"surf-icon.svg"}/>
                <p>9am</p>
                <p>Join us in the Surf</p>
            </div>
            <div className={"section"}>
                <img src={"rounders-icon.svg"}/>
                <p>11am</p>
                <p>Rounders on the Beach</p>
            </div>
            <hr/>
            <h2>Saturday Afternoon</h2>
            <div className={"section"}>
                <img src={"drinks-glasses.svg"}/>
                <p>1pm</p>
                <p>Welcome Drinks</p>
            </div>
            <div className={"section"}>
                <img src={"cake-icon.svg"}/>
                <p>2pm</p>
                <p>Cutting the Cake</p>
            </div>
            <div className={"section"}>
                <img src={"jj-logo.svg"}/>
                <p>3pm</p>
                <p>Live Music</p>
            </div>
            <div className={"section"}>
                <p>5:45pm</p>
                <p>First Dance</p>
            </div>
            <div className={"section"}>
                <img src={"disco-ball.svg"}/>
                <p>6pm</p>
                <p>Disco</p>
            </div>
            <div className={"section"}>
                <p>10:30pm</p>
                <p>Last Song</p>
            </div>
            <hr/>
            <h2>Sunday</h2>
            <p>Campers to check-out by 10:30am</p>
        </div>
    </div>
)

export default Timeline